var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-notify" }, [
    _c("img", {
      attrs: { src: require("@/assets/images/lego/notify_icon.png"), alt: "" },
    }),
    _c("span", { staticClass: "notify-content" }, [
      _vm._v(" " + _vm._s(_vm.notify) + " "),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "more-span" }, [
      _vm._v("\n    更多\n    "),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }