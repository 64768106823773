<template>
  <div class="props-edit-banner">
    <el-carousel height="160px">
      <el-carousel-item
        class="single-carousel-item"
        v-for="(item, index) in imageList"
        :key="index"
      >
        <img v-if="item.fileId" :src="imageRender(item.fileId)" alt="" />
        <i v-else class="el-icon-picture-outline"></i>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { cloneDeep } from "lodash";

export default {
  name: "props-edit-banner",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      imageList: [],
      resourceList: [],
    };
  },
  watch: {
    dataset: {
      handler(itemDatas) {
        const datas = itemDatas.props;
        if (datas && datas.count) {
          const tempList = cloneDeep(this.$store.getters.legoBannerList);
          const diff = datas.count - tempList.length;
          if (diff > 0) {
            this.imageList = cloneDeep(
              tempList.concat(new Array(diff).fill({}))
            );
          } else {
            this.imageList = cloneDeep(tempList.splice(0, datas.count));
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-banner {
  height: 160px;
  background: #fff;
  border-radius: 8px;
  .single-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-picture-outline {
      font-size: 40px;
      color: #ccc;
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  ::v-deep .el-carousel__button {
    background-color: lightgray;
  }
  ::v-deep .el-carousel__indicator.is-active button {
    background-color: gray;
  }
}
</style>