<template>
  <div class="props-edit-notify">
    <img src="@/assets/images/lego/notify_icon.png" alt="" />
    <span class="notify-content"> {{ notify }} </span>
    <span class="more-span">
      更多
      <i class="el-icon-arrow-right"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "props-edit-notify",
  data() {
    return {
      notify: this.$store.getters.legonNotifyList.noticeTitle,
    };
  },
};
</script>

<style lang="less" scoped>
.props-edit-notify {
  height: 38px;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  .notify-content {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 260px;
    cursor: default;
  }
  .more-span {
    font-weight: 400;
    font-size: 12px;
    color: #c9cbd2;
    cursor: default;
  }
}
</style>