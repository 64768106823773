var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-bar-component" }, [
    _c(
      "div",
      { staticClass: "menu-container" },
      _vm._l(_vm.menuList, function (item) {
        return _c(
          "div",
          {
            key: item.key,
            class: ["single-menu", item.active && "single-menu-active"],
            style: {
              cursor: item.key === "my" ? "not-allowed" : "pointer",
            },
            on: {
              click: function ($event) {
                return _vm.setActive(item)
              },
            },
          },
          [
            _c("img", { attrs: { src: _vm.iconRender(item), alt: "" } }),
            _c("div", { staticClass: "menu-label" }, [
              _vm._v("\n        " + _vm._s(item.label) + "\n      "),
            ]),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "bottom-border" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }