<template>
  <div class="props-edit-functions" @click.stop="setItemActive">
    <div class="card-label" v-if="currentPage === 'more'">
      {{ dataset.label }}
    </div>
    <div class="card-containers">
      <div
        :class="['single-card', item.active && 'single-card-active']"
        v-for="(item, index) in dataset.props.filter((item) => item.checked)"
        :key="index"
        @click.stop="setItemActive(index)"
      >
        <img
          v-if="item.background"
          class="card-icon"
          :src="imageRender(item.background)"
          alt=""
        />
        <i class="default-icon el-icon-question" v-else></i>
        <div class="card-title">
          {{ item.title }}
        </div>
      </div>
      <div
        v-show="source !== 'preview'"
        class="single-card add-icon"
        @click.stop="addNewOne"
      >
        <i class="el-icon-plus"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getSingleFunctionObj } from "../datas.js";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-functions",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    active: {
      type: Boolean,
      default() {
        return false;
      },
    },
    source: {
      type: String,
      default() {
        return "";
      },
    },
    currentPage: {
      type: String,
      default() {
        return "home";
      },
    },
  },
  watch: {
    active: {
      handler(data) {
        // 未激活时，把没有通过校验并保存的数据清除掉
        if (!data) {
          this.dataset.props.forEach((item, index) => {
            item.active = false;
            if (!item.checked) {
              this.dataset.props.splice(index, 1);
            }
          });
        }
      },
    },
  },
  methods: {
    addNewOne() {
      if (!this.active) {
        this.$emit("setActive");
        return;
      }
      this.setItemActive();
      // 添加一个function对象，并且激活，能在props区域编辑，但是在graph区域不展示
      this.dataset.props.push(getSingleFunctionObj());
    },
    setItemActive(ind) {
      if (!this.active) {
        this.$emit("setActive");
        return;
      }
      this.dataset.props
        .filter((item) => item.checked)
        .forEach((item, index) => {
          item.active = index === ind;
        });
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-functions {
  background: #fff;
  border-radius: 8px;
  .card-label {
    height: 24px;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #30313b;
    padding-left: 12px;
  }
  .card-containers {
    min-height: 90px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px 33px;
    .single-card {
      width: 50px;
      height: 52px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .card-icon {
        width: 32px;
        height: 32px;
      }
      .default-icon {
        margin-top: 8px;
        font-size: 20px;
        color: #878787;
      }
      .card-title {
        font-weight: 400;
        font-size: 12px;
        color: #30313b;
        text-align: center;
      }
    }
    .single-card-active {
      border: 1px solid #2a61ff;
      border-radius: 10px;
    }
    .add-icon {
      width: 48px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #e4e7eb;
      font-size: 18px;
      color: #b5bece;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>