var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "props-edit-functions",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.setItemActive.apply(null, arguments)
        },
      },
    },
    [
      _vm.currentPage === "more"
        ? _c("div", { staticClass: "card-label" }, [
            _vm._v("\n    " + _vm._s(_vm.dataset.label) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "card-containers" },
        [
          _vm._l(
            _vm.dataset.props.filter((item) => item.checked),
            function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: ["single-card", item.active && "single-card-active"],
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.setItemActive(index)
                    },
                  },
                },
                [
                  item.background
                    ? _c("img", {
                        staticClass: "card-icon",
                        attrs: {
                          src: _vm.imageRender(item.background),
                          alt: "",
                        },
                      })
                    : _c("i", { staticClass: "default-icon el-icon-question" }),
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v("\n        " + _vm._s(item.title) + "\n      "),
                  ]),
                ]
              )
            }
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.source !== "preview",
                  expression: "source !== 'preview'",
                },
              ],
              staticClass: "single-card add-icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addNewOne.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }