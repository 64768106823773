<template>
  <div class="props-edit-service4" @click="setActive">
    <div
      :class="['single-card-service4', item.active && 'single-card-active']"
      @click.stop="setActive(index)"
      v-for="(item, index) in dataset.props"
      :key="index"
    >
      <div class="left-lines">
        <div class="title-line overflow-ellipse">
          {{ item.title }}
        </div>
        <div class="description-line overflow-ellipse">
          {{ item.description }}
        </div>
      </div>
      <div class="icon-container">
        <img :src="imageRender(item.background, index)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-service4",
  props: {
    active: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
      default() {
        return "blue";
      },
    },
  },
  watch: {
    active: {
      handler(data) {
        if (!data) {
          this.dataset.props.forEach((item) => {
            item.active = false;
          });
        }
      },
    },
  },
  methods: {
    imageRender(fileId, index) {
      if (fileId) {
        return (
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          fileId
        );
      }
      return require(`@/assets/images/lego/${this.theme}/service4_card_${index}.png`);
    },
    activeRender(index) {
      let result = false;
      this.dataset.props.forEach((ele, ind) => {
        if (index === ind) {
          result = ele.active;
        }
      });
      return result;
    },
    setActive(ind) {
      if (!this.active) {
        this.$emit("setActive");
        return;
      }
      this.dataset.props.forEach((item, index) => {
        item.active = ind === index;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-service4 {
  border-radius: 8px;
  height: 160px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  .overflow-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .single-card-service4 {
    flex: 1;
    min-width: 165px;
    height: 74px;
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border: 1px solid #fff;
    .left-lines {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .title-line {
        font-weight: 600;
        font-size: 16px;
        color: #30313b;
      }
      .description-line {
        font-weight: 400;
        font-size: 12px;
        color: #828798;
        margin-top: 8px;
      }
    }
    .item-icon {
      width: 46px;
      height: 46px;
    }
    .icon-container {
      height: 46px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .single-card-active {
    border: 1px solid #2a61ff;
  }
}
</style>