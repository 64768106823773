<template>
  <div class="props-edit-news">
    <div class="news-title-line">
      <span class="news-title-label"> 精彩推荐 </span>
      <span class="more-span">
        更多
        <i class="el-icon-arrow-right"></i>
      </span>
    </div>
    <div class="news-container" v-for="(item, index) in newsList" :key="index">
      <div class="single-news" v-if="item.id">
        <el-image
          class="news-image"
          placeholder="加载中..."
          :src="imageRender(item.photoIdListId[0])"
          lazy
        ></el-image>
        <div class="right-part">
          <div class="title-line">
            {{ item.activityTitle }}
          </div>
          <div class="address-line">
            {{ item.activityAddress }}
          </div>
          <div class="date-line">
            {{ item.updateTime | dateFormat("YYYY年MM月DD日 HH:mm:ss") }}
          </div>
        </div>
      </div>
      <div v-else class="empty-container">
        <i class="el-icon-picture-outline"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-news",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      newsList: [],
    };
  },
  watch: {
    dataset: {
      handler(itemDatas) {
        const datas = itemDatas.props;
        if (datas && datas.count) {
          const tempList = cloneDeep(this.$store.getters.legonNewsList);
          const diff = datas.count - tempList.length;
          this.newsList =
            datas.count - tempList.length > 0
              ? tempList.concat(new Array(diff).fill({}))
              : tempList.splice(0, datas.count);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-news {
  .news-title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .news-title-label {
      font-weight: 500;
      font-size: 14px;
      color: #30313b;
    }
    .more-span {
      font-weight: 400;
      font-size: 12px;
      color: #c9cbd2;
      cursor: default;
    }
  }
  .news-container {
    background: #fff;
    height: 84px;
    border-radius: 10px;
    .single-news {
      height: 100%;
      width: 100%;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .news-image {
        width: 90px;
        height: 60px;
        border-radius: 5px;
      }
      .right-part {
        flex: 1;
        margin-left: 12px;
        .title-line {
          font-weight: 500;
          font-size: 14px;
          color: #30313b;
        }
        .address-line {
          font-weight: 400;
          font-size: 12px;
          color: #828798;
          margin-top: 6px;
        }
        .date-line {
          font-weight: 400;
          font-size: 12px;
          color: #c9cbd2;
          margin-top: 14px;
        }
      }
    }
    .empty-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-icon-picture-outline {
        font-size: 40px;
        color: #ccc;
      }
    }
  }
  .news-container + .news-container {
    margin-top: 12px;
  }
}
</style>