import { cloneDeep } from 'lodash';

export const componentsList = [
  {
    key: 'theme',
    label: '主题',
    active: true,
    icon: require('../../../../assets/images/lego/theme-icon.png'),
    list: [
      {
        key: 'blue',
        label: '锦绣蓝',
        color: '#1A4CEC',
        active: true,
      },
      {
        key: 'yellow',
        label: '金威黄',
        color: '#FFB800',
        active: false,
      },
      {
        key: 'red',
        label: '动漫红',
        color: '#F7168F',
        active: false,
      },
    ],
  },
  /**
   *  banner: banner
      通知公告：notify
      新闻资讯：news
      金刚区：functions
      三魔方：services3
      四魔方：services4
   */
  {
    key: 'components',
    label: '小程序组件',
    active: true,
    icon: require('../../../../assets/images/lego/components-icon.png'),
    list: [
      {
        show: true,
        key: 'banner',
        label: 'banner',
        icon: require('../../../../assets/images/lego/component_icon_banner.png'),
      },
      {
        show: true,
        key: 'functions',
        label: '金刚区',
        icon: require('../../../../assets/images/lego/component_icon_functions.png'),
      },
      {
        show: true,
        key: 'notify',
        label: '通知公告',
        icon: require('../../../../assets/images/lego/component_icon_notify.png'),
      },
      {
        show: true,
        key: 'services3',
        label: '三魔方',
        icon: require('../../../../assets/images/lego/component_icon_services3.png'),
      },
      {
        show: true,
        key: 'news',
        label: '新闻资讯',
        icon: require('../../../../assets/images/lego/component_icon_news.png'),
      },
      {
        show: true,
        key: 'services4',
        label: '四魔方',
        icon: require('../../../../assets/images/lego/component_icon_services4.png'),
      },
    ],
  },
];

export const getComponentsList = () => {
  return cloneDeep(componentsList);
};

const singleFunctionObj = {
  active: false,
  title: '',
  description: '',
  background: null, // default | fileId
  jumpMethod: 'link', // link | function | outer
  linkObj: {
    linkUrl: '',
    linkDescription: '',
  },
  functionObj: {
    functionId: null,
  },
  outerObj: {
    appId: '', // appId（必填）
    pageUrl: '', //  页面地址（没传pageUrl的话，跳转到小程序首页）
  },
};

const configDatasMaps = {
  banner: {
    count: 3,
  },
  notify: {},
  news: {
    count: 2,
  },
  services3: new Array(3).fill(null).map(() => cloneDeep(singleFunctionObj)),
  services4: new Array(4).fill(null).map(() => cloneDeep(singleFunctionObj)),
  functions: [],
};

export const getPropsByType = (type) => {
  return configDatasMaps[type];
};

export const getSingleFunctionObj = () => {
  let result = cloneDeep(singleFunctionObj);
  result.checked = false;
  result.active = true;
  console.log('result', JSON.stringify(result));
  return result;
};
