<template>
  <div class="props-edit-service3" @click="setActive">
    <div
      @click.stop="setActive(0)"
      :class="['the-one single-card', activeRender(0) && 'single-card-active']"
      :style="{
        backgroundImage:
          'url(' +
          require(`@/assets/images/lego/${theme}/service_card_back.png`) +
          ')',
      }"
    >
      <div class="title-line overflow-ellipse">
        {{ dataset.props[0].title }}
      </div>
      <div class="description-line">
        {{ dataset.props[0].description }}
      </div>
      <div class="card-icon">
        <img :src="imageRender(dataset.props[0].background, 0)" alt="" />
      </div>
    </div>
    <div class="right-part">
      <div
        @click.stop="setActive(1)"
        :class="[
          'the-two single-card',
          activeRender(1) && 'single-card-active',
        ]"
      >
        <div class="left-lines">
          <div class="title-line overflow-ellipse">
            {{ dataset.props[1].title }}
          </div>
          <div class="description-line overflow-ellipse">
            {{ dataset.props[1].description }}
          </div>
        </div>
        <div class="icon-container">
          <img :src="imageRender(dataset.props[1].background, 1)" alt="" />
        </div>
      </div>
      <div
        @click.stop="setActive(2)"
        :class="[
          'the-three single-card',
          activeRender(2) && 'single-card-active',
        ]"
      >
        <div class="left-lines">
          <div class="title-line overflow-ellipse">
            {{ dataset.props[2].title }}
          </div>
          <div class="description-line overflow-ellipse">
            {{ dataset.props[2].description }}
          </div>
        </div>
        <div class="icon-container">
          <img :src="imageRender(dataset.props[2].background, 2)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-service3",
  props: {
    active: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
      default() {
        return "blue";
      },
    },
  },
  watch: {
    active: {
      handler(data) {
        if (!data) {
          this.dataset.props.forEach((item) => {
            item.active = false;
          });
        }
      },
    },
  },
  methods: {
    imageRender(fileId, index) {
      if (fileId) {
        return (
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          fileId
        );
      }
      return require(`@/assets/images/lego/${this.theme}/service_card_${index}.png`);
    },
    activeRender(index) {
      let result = false;
      this.dataset.props.forEach((ele, ind) => {
        if (index === ind) {
          result = ele.active;
        }
      });
      return result;
    },
    setActive(ind) {
      if (!this.active) {
        this.$emit("setActive");
        return;
      }
      this.dataset.props.forEach((item, index) => {
        item.active = ind === index;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-service3 {
  border-radius: 8px;
  height: 160px;
  width: 100%;
  display: flex;
  gap: 8px;
  .overflow-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-line {
    font-weight: 600;
    font-size: 16px;
    color: #30313b;
  }
  .description-line {
    font-weight: 400;
    font-size: 12px;
    color: #828798;
    margin-top: 8px;
  }
  .the-one {
    width: 170px;
    padding: 20px 12px;
    background-size: 100% 100% !important;
    position: relative;
    .description-line {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      line-height: 14px;
      padding-right: 50px;
    }
    .card-icon {
      position: absolute;
      right: 1px;
      bottom: 1px;
      width: 84px;
      height: 92px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .right-part {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .the-two {
      height: 74px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }
    .the-three {
      height: 74px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }
    .icon-container {
      height: 46px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .single-card {
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ffffff;
    .left-lines {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .single-card-active {
    border: 1px solid #2a61ff;
  }
}
</style>