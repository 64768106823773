<template>
  <div class="menu-bar-component">
    <div class="menu-container">
      <div
        :class="['single-menu', item.active && 'single-menu-active']"
        :style="{
          cursor: item.key === 'my' ? 'not-allowed' : 'pointer',
        }"
        v-for="item in menuList"
        :key="item.key"
        @click="setActive(item)"
      >
        <img :src="iconRender(item)" alt="" />
        <div class="menu-label">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="bottom-border"></div>
  </div>
</template>

<script>
export default {
  name: "menu-bar-component",
  props: {
    theme: {
      type: String,
      default() {
        return "blue";
      },
    },
    currentPage: {
      type: String,
      default() {
        return "home";
      },
    },
  },
  watch: {
    currentPage: {
      handler(page) {
        this.menuList.forEach((item) => {
          item.active = page === item.key;
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      menuList: [
        {
          key: "home",
          label: "首页",
          active: true,
        },
        {
          key: "more",
          label: "更多服务",
          active: false,
        },
        {
          key: "my",
          label: "我的",
          active: false,
        },
      ],
    };
  },
  methods: {
    setActive({ key }) {
      if (key === "my") {
        return;
      }
      this.menuList.forEach((item) => {
        item.active = item.key === key;
      });
      this.$emit("pageChange", key);
    },
    iconRender({ key, active }) {
      return require(`../../../../../assets/images/lego/${
        active ? this.theme : "default"
      }/menu_${key}_icon.png`);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-bar-component {
  width: 100%;
  height: 88px;
  background: #fff;
  padding: 10px 20px 8px;
  box-shadow: 0px -2px 10px 0px rgba(129, 139, 152, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .single-menu {
      width: 68px;
      height: 40px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .menu-label {
        font-weight: 400;
        font-size: 12px;
        color: #bbc3ce;
        margin-top: 6px;
      }
    }
    .single-menu-active {
      .menu-label {
        color: #30313b !important;
      }
    }
  }
  .bottom-border {
    height: 5px;
    width: 134px;
    background: #010101;
    border-radius: 11px;
  }
}
</style>