var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-edit-service4", on: { click: _vm.setActive } },
    _vm._l(_vm.dataset.props, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: ["single-card-service4", item.active && "single-card-active"],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.setActive(index)
            },
          },
        },
        [
          _c("div", { staticClass: "left-lines" }, [
            _c("div", { staticClass: "title-line overflow-ellipse" }, [
              _vm._v("\n        " + _vm._s(item.title) + "\n      "),
            ]),
            _c("div", { staticClass: "description-line overflow-ellipse" }, [
              _vm._v("\n        " + _vm._s(item.description) + "\n      "),
            ]),
          ]),
          _c("div", { staticClass: "icon-container" }, [
            _c("img", {
              attrs: { src: _vm.imageRender(item.background, index), alt: "" },
            }),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }