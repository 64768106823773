var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-edit-banner" },
    [
      _c(
        "el-carousel",
        { attrs: { height: "160px" } },
        _vm._l(_vm.imageList, function (item, index) {
          return _c(
            "el-carousel-item",
            { key: index, staticClass: "single-carousel-item" },
            [
              item.fileId
                ? _c("img", {
                    attrs: { src: _vm.imageRender(item.fileId), alt: "" },
                  })
                : _c("i", { staticClass: "el-icon-picture-outline" }),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }